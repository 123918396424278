import { createContext, useContext, useEffect, useReducer } from "react";
import { auth } from "@/utils/firebase";
import { useRouter } from "next/router";
import axios from "axios";

const Context = createContext<any>([]);
const { Provider } = Context;
let mql: any;

const AppProvider = (props: any) => {
  const saveToLocal = (state: any) => {
    let stateCopy = { ...state };
    delete stateCopy.mobile;
    localStorage.setItem("settings", JSON.stringify(stateCopy));
  };
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "setFetched": {
        const newState = { ...state, fetched: action.payload };
        saveToLocal(newState);
        return newState;
      }
      // dark mode
      case "toggleDarkMode": {
        return { ...state, darkMode: action.payload };
      }
      case "toggleToppageAbout": {
        return { ...state, toppageAbout: action.payload };
      }
      case "setUserInfo": {
        const newState = { ...state, userInfo: action.payload };
        saveToLocal(newState);
        return newState;
      }
      case "setFirebaseInfo": {
        const newState = { ...state, firebaseInfo: action.payload };
        saveToLocal(newState);
        return newState;
      }
      case "setGithubToken": {
        const newState = { ...state, githubToken: action.payload };
        saveToLocal(newState);
        return newState;
      }

      case "toggleEditorTutrial": {
        const newState = { ...state, editorTutrial: !state.editorTutrial };
        saveToLocal(newState);
        return newState;
      }
      case "setRefresh": {
        const newState = { ...state, refresh: state.refresh + 1 };
        saveToLocal(newState);
        return newState;
      }
      case "setLoginModal": {
        const newState = { ...state, loginModal: action.payload };
        saveToLocal(newState);
        return newState;
      }
      case "setMobile":
        return { ...state, mobile: !mql.matches };
      case "initialSetup": {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const darkMode =
          localStorage.theme === "dark" ||
          (!("theme" in localStorage) &&
            window.matchMedia("(prefers-color-scheme: dark)").matches);
        const toppageAbout = localStorage.toppageAbout === "about-show";
        return {
          ...state,
          mobile: !mql.matches,
          ...settings,
          darkMode,
          toppageAbout,
        };
      }
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    name: "Commutty IT",
    mobile: false,
    darkMode: false,
    userInfo: false,
    fetched: false,
    firebaseInfo: false,
    toppageAbout: true,
    editorTutrial: true,
    githubToken: false,
    refresh: 0,
    loginModal: false,
  });
  const router = useRouter();
  const user_url = `${process.env.BACKEND_URL}/v1/notebox/account/self`;
  useEffect(() => {
    mql = window.matchMedia(`(min-width: 992px)`);
    mql.addListener(mediaQueryChanged);
    dispatch({ type: "initialSetup" });
    // ログイン済みの場合はユーザー情報の更新に関わらず、一旦その情報を再利用
    dispatch({ type: "setFetched", payload: !!state.userInfo });
    auth.onAuthStateChanged((user: any) => {
      if (user) {
        dispatch({ type: "setFirebaseInfo", payload: user });
        user.getIdToken(true).then((idToken: any) => {
          axios
            .get(user_url, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${idToken}`,
              },
            })
            .then((response) => {
              const slug = response.data.user.slug;
              //onBoarding判定。してなかったらonBoardingページに笑
              const onboarded = slug !== user.uid;
              if (onboarded) {
                // 情報更新分をローカルに反映
                dispatch({ type: "setUserInfo", payload: response.data });
                dispatch({ type: "setFetched", payload: true });
              } else {
                router.push("/onboarding?phase=1", "/onboarding");
              }
            })
            .catch((error) => {
              // TODO エラー処理追加
            });
        });
      } else {
        dispatch({ type: "setFirebaseInfo", payload: undefined });
        dispatch({ type: "setUserInfo", payload: undefined });
        dispatch({ type: "setFetched", payload: true });
      }
    });
    return () => mql.removeListener(mediaQueryChanged);
  }, [state.refresh]);

  const mediaQueryChanged = () => {
    dispatch({ type: "setMobile" });
  };

  return <Provider value={[state, dispatch]}>{props.children}</Provider>;
};

export default AppProvider;
export const useAppState = () => useContext(Context);
