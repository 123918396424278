//https://panda-program.com/posts/nextjs-google-analytics
export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
// IDが取得できない場合を想定する
export const existsGaId = GA_ID !== "";

declare global {
  interface Window {
    gtag: any;
  }
}
// PVを測定する
export const pageview = (path: any) => {
  if (!window.gtag) {
  } else {
    window.gtag("config", GA_ID, {
      page_path: path,
    });
  }
};

// GAイベントを発火させる
export const event = ({ action, category, label, value = "" }: any) => {
  if (!window.gtag) {
    return;
  } else {
    window.gtag("event", action, {
      event_category: category,
      event_label: JSON.stringify(label),
      value,
    });
  }
};
