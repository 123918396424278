import Image from "next/image";
import Link from "next/link";
import { Dialog, Input, Button, IconButton, Grid } from "@mui/material";
import { Twitter, Close } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppState } from "@/contexts/AppProvider";
import { getLocale } from "@/lang/get-locale";
import { ErrorField } from "../features/parts/ErrorField";

export default function Fooder() {
  const { t } = getLocale();
  const [state] = useAppState();

  const notebox_form_url = `${process.env.BACKEND_URL}/v1/notebox/form`; //notebox用のお問合せ

  const [feedback, setFeedback] = useState<any>(false);
  const [value, setValue] = useState<any>("");
  const [sending, setSending] = useState<any>(false);
  const [sent, setSent] = useState<any>(false);
  const [email, setEmail] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const handleChange = (e: any) => {
    e.preventDefault();
    setValue(e.target.value);
  };
  //お問合せを送信する関数
  const sendHandler = () => {
    setSending(true);
    const json = { message: value, email: email || "miss@example.com" };
    axios
      .post(notebox_form_url, json)
      .then(() => {
        setSending(false);
        setValue("");
        setSent(true);
        // 正常終了のため空を保持する
        setErrorMessage("");
      })
      .catch(() => {
        setErrorMessage(t.CHECKNETWORKCONNECTION);
        setSending(false);
        setSent(false);
      });
  };
  useEffect(() => {
    const firebaseEmail = state.firebaseInfo ? state.firebaseInfo.email : "";
    setEmail(firebaseEmail);
  }, [state]);
  return <>
    <Grid container className="dark:bg-black pb-10">
      <Grid item md={2} className="hidden md:block"></Grid>
      <Grid item md={8} className="block md:flex text-sm pt-8">
        <div className="pl-3">
          <Link scroll={false} href="/">

            <div className="flex items-center">
              <div
                style={{
                  height: "40px",
                  lineHeight: "40px",
                }}
              >
                <Image
                  src="/commutty_it.svg"
                  alt="Commutty IT Logo"
                  width={40}
                  height={40}
                />
              </div>
              <div className="block text-3xl font-bold pl-2" style={{ whiteSpace: 'nowrap' }}>Commutty IT</div>
            </div>
            <div className="pt-3 text-gray-500" style={{ whiteSpace: 'nowrap' }}>
              A Community For IT Engineers
            </div>

          </Link>
          <div>
            <div className="flex py-4">
              <span className="pr-2 no-change-link">
                <a
                  href="https://twitter.com/Commutty_IT"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Twitter
                    style={{
                      width: "22px",
                      height: "22px",
                      color: "#626262",
                    }}
                  />
                </a>
              </span>

              <span className="pl-2">
                <a
                  href="https://join.slack.com/t/magicodeio/shared_invite/zt-rvx96n00-tH4rGBrHs3xbv1xf4A5Y~Q"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Image
                    src="/icons/slack.svg"
                    width={22}
                    height={22}
                    alt="slack logo"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
        <Grid container justifyContent={{ md: 'flex-end' }}>
          <div className="md:pt-0">
            <div className="block md:flex">
              <div className="py-2 px-3">
                <a
                  href="https://it.commutty.com/CommuttyIT/articles/8848b5388b5940f19576ce5f8b8c9efc"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  About
                </a>
              </div>
              <div className="py-2 px-3">
                <Link scroll={false} href="/terms">
                  {t.TERMS}
                </Link>
              </div>
              <div className="py-2 px-3">
                <Link scroll={false} href="/privacy">
                  {t.PRIVACYPOLICIES}
                </Link>
              </div>
              <div className="py-2 px-3">
                <a
                  href="https://it.commutty.com/CommuttyIT/articles/d90b9c0127ae468288e34f54c675abab"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  ヘルプ
                </a>
              </div>
              <div
                onClick={() => {
                  setFeedback(!feedback);
                }}
                className="py-2 px-3"
              >
                <a>{t.CONTACT}</a>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>

    <Dialog
      onClose={() => {
        setFeedback(false);
        setSent(false);
      }}
      open={feedback}
      className="text-center"
    >
      <div style={{ padding: "24px", width: "600px", position: "relative" }}>
        {sent ? (
          <>
            <h4>
              <b>Thank you for your feedback!</b>
            </h4>
          </>
        ) : (
          <>
            <div className="py-3">
              <h4>
                <b>{t.CONTACT}</b>
              </h4>
            </div>

            <div className="py-3">
            {errorMessage && (<ErrorField errorMessage={errorMessage}/>)}
              <Input
                required
                id="commentinput"
                placeholder={t.YOUROPINION}
                multiline
                type="text"
                inputProps={{ maxLength: 254 }}
                value={value}
                onChange={handleChange}
                style={{ width: "100%" }}
                className="text-black dark:text-white"
              />

              <div className="py-2 text-center">
                <Input
                  required
                  id="emailinput"
                  placeholder="Email adress"
                  autoComplete="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  style={{ width: "100%" }}
                  className="text-black dark:text-white"
                />
              </div>

              <div className="py-1">
                <Button
                  onClick={sendHandler}
                  disabled={sending || value.trim() === ""}
                  variant="contained"
                  color="secondary"
                >
                  <b style={{ color: "white" }}>{t.SUBMIT}</b>
                </Button>
              </div>
            </div>
          </>
        )}

        <div style={{ position: "absolute", top: "5px", right: "5px" }}>
          <IconButton
            onClick={() => {
              setFeedback(false);
            }}
            color="inherit"
          >
            <Close />
          </IconButton>
        </div>
      </div>
    </Dialog>
  </>;
}
